.configurator {
  &-result-description {
    background-color: #fff;
    display: flex;
    padding: 50px 20px 20px;

    &__info-icon {
      margin-right: 20px;
      filter: invert(29%) sepia(99%) saturate(5489%) hue-rotate(336deg) brightness(92%) contrast(101%);
      width: 30px;
    }

    &__info {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media(max-width: 1023px) {
      &__info {
        font-size: 16px;
      }
    }

    @media(max-width: 767px) {
      padding: 25px;
      &__paragraph-section {
        padding: 0 25px 25px;
      }
    }
  }
}
