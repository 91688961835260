.configurator {
  &-result-paragraph {
    padding: 0 70px 20px;

    &:last-child {
      padding: 0 70px 30px;
    }

    &__pointer {
      color: #ed1846;
      width: 15px !important;
      margin-right: 15px;
    }

    &__text {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      display: flex;
    }

    @media(max-width: 1023px) {
      &__text {
        font-size: 12px;
      }
    }

    @media(max-width: 767px) {
      padding: 0 0 20px;
      &:last-child {
        padding: 0 0 5px;
      }
    }
  }
}
