.configurator {
  &-result-card {
    width: 410px;
    margin: 0 10px 35px;
    position: relative;
    border: 1px solid #000;

    &__content {
      padding: 20px;
      min-height: 200px;
    }

    > img {
      width: 100%
    }

    &__feedback, &__description {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      color: #000;
      text-align: left;
      margin-bottom: 10px;
      align-items: center;
      display: flex;
    }

    &__description {
      margin-bottom: 25px;
    }

    &__title {
      font-size: 18px;
      font-family: Roboto Condensed;
      line-height: 2em;
      font-size: 2.1em;
      color: #000000;
      min-width: 110px;
      margin-bottom: 10px;
    }

    &__button-section {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 60px;
      padding: 2em;
      align-items: center;
      margin-bottom: 20px;
    }

    &__button {
      cursor: pointer;
      background-color: #22262f;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      color: #f0f1f5;
      text-decoration: none;
      font-family: Roboto;
      line-height: 55px;
      letter-spacing: 0.8px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-content: center;
      margin-left: auto;
      align-items: center;
    }

    &__price {
      padding: 10px 30px;
      color: #000;
      margin: 0 auto;
      text-align: center;
      align-items: center;
      font-family: Roboto;
      font-size: 24px;


      &--discount {
        text-decoration: line-through;
      }
    }

    &__discount-price {
      position: absolute;
      padding: 10px 30px;
      background-color: #ed1846;
      color: #ffff;
      top: 45px;
      right: 0;
    }

    &__star-icon {
      &--active-star {
        color: #F7CF39;
      }
    }

    @media(max-width: 1023px) {
      max-width: 300px;
      &__button {
        margin: 10px auto 0;
      }
    }
  }
}
