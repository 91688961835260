.configurator {
  &-results {
    background-color: #fff;
    position: relative;
    animation: showingUp 0.5s;
    animation-directon: normal;

    @keyframes showingUp {

      0% {
        opacity: 0;
        -webkit-animation-timing-function: ease-in;
      }

      100% {
        opacity: 1;
        animation-timing-function: ease-out;
      }
    }
  }
}
