.configurator {
  &-question-footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &__alert {
      background-color: #ed1846 !important;
      animation: showingUp 0.5s;
      flex: 0 0 100%;
      animation-directon: normal;
      border-radius: 0 !important;
      width: 100%;
      padding: 5px !important;

      @keyframes showingUp {

        0% {
          opacity: 0;
          -webkit-animation-timing-function: ease-in;
        }


        100% {
          opacity: 1;
          animation-timing-function: ease-out;
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      padding: 16px 40px;
      cursor: pointer;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
      font-weight: normal;
      font-style: normal;
      width: 190px;
      line-height: 1.19;
      letter-spacing: 0.8px;
      border: none;
      outline: none;
      margin: 3px 0;
      transition: 0.3s all;
      font-size: 14px;

      &--previous {
        color: #22262f;

        > svg {
          margin-right: 7px;
        }
      }

      &--next {
        border: solid 2px #22262f;
        background-color: #22262f;
        color: #f0f1f5;

        > svg {
          margin-left: 7px;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      &__button {
        &--previous {
          margin: 0 0 15px;
        }
      }
    }
  }
}

.MuiAlert-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}

.MuiAlert-message {
  margin: auto 0;
  font-size: 12px;
}

