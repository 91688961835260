.configurator-form {
  margin-top: 60px;
  background-color: #f7f7f7;
  animation: showingUp 0.5s;
  border: 2px solid #a5c0cf;
  position: relative;

  @keyframes showingUp {
    0% {
      opacity: 0;
      -webkit-animation-timing-function: ease-in;
    }

    100% {
      opacity: 1;
      animation-timing-function: ease-out;
    }
  }

  &__header {
    padding: 30px 0;
    background-color: #a5c0ce;
    display: flex;
    justify-content: center;
    position: relative;

    &:after {
      background-color: #1b1d28;
      width: 80%;
      height: 1px;
      z-index: 0;
      top: 50%;
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__header-mask {
    background-color: #a5c0ce;
    position: relative;
    z-index: 1;
    padding: 0 50px;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    color: #0b1734;
    margin-bottom: 12px;
  }

  &__paragraph {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 10px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
    color: #0b1734;
  }

  &__loader {
    color: #ed1846 !important;
    z-index: 9999;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    background-size: contain;
    margin: auto;

    &--result {

    }
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
}
