.configurator {
  &-question-body {
    display: flex;
    justify-content: center;
    padding: 0 10px;
    animation: showingUp 0.5s;
    animation-directon: normal;

    @keyframes showingUp {

      0% {
        opacity: 0;
        -webkit-animation-timing-function: ease-in;
      }


      100% {
        opacity: 1;
        animation-timing-function: ease-out;
      }
    }

    &__button {
      margin: 0 5px;
      min-width: 200px;
      max-width: 400px;
      display: flex;
      cursor: pointer;
      line-height: 1.2;
      color: #000000;
      text-align: center;
      padding: 23px 32px;
      border: solid 1px #22262f;
      background-color: #e0e0e0;
      outline: none;
      transition: 0.3s ease;
      min-height: 100%;
      display: flex;
      flex-direction: column;

      &:hover {
        transform: scale(1.02);
        border-color: #DA92A6;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      }

      > .button-title {
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #000000;
        text-align: left;
        margin: 0;
      }

      > h3 {
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #000000;
        text-align: left;
        margin: 0;
      }

      > p {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #5f6269;
        text-align: left;
      }

      &--small {
        display: flex;
        align-items: center;

      }

      &--large {
        min-height: 100%;

      }

      &--selected {
        border: solid 1px #ed1846;
        background-color: #DA92A6;

        > h2, p {
          color: #ffff;
        }
      }
    }

    @media(max-width: 1023px) {
      display: flex;
      align-items: center;
      flex-direction: column;

      &__button {
        margin: 10px auto;
        width: 100%;
        justify-content: center;

        > h2 {
          font-size: 16px;
          text-align: center;
        }

        &--small {
          min-height: 60px;
        }

        &--large {
          min-height: 200px;
          align-items: center;

          > p {
            text-align: center;
          }

        }
      }
    }
  }
}
