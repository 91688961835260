.configurator-question-header {
  display: flex;
  padding: 46px 16px;
  align-items: center;
  position: relative;
  transition: 0.5s ease-in-out;
  justify-content: space-between;

  &__check-icon {
    margin-right: 20px;
    color: #ed1846;
    width: 30px !important;
    height: 25px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__right-side {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__left-side {
    display: flex;
    align-items: center;
    flex: 0 0 1.25;
  }

  &__display-order {
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #22262f;
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Lato", sans-serif;

    &--grayed-out {
      opacity: 0.5;
    }
  }

  &__heading {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.2;
    color: #000000;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    margin-bottom: 0px;

    &--grayed-out {
    }
  }

  &__hr {
    width: 0;
    height: 40px;
    opacity: 0.5;
    border: solid 1px #8e95a5;
    margin: 0 20px 0 0;

    &--grayed-out {
      opacity: 0.5;
    }
  }

  &__info-icon {
    width: 30px;

    &--lower-margin {
      margin: 0 100px 0 20px;
    }
  }

  &__answer {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ed1846;
    display: inline-block;
    margin: 0;
    white-space: nowrap;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    float: left;
  }

  &__placeholder {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #8e95a5;
    opacity: 0.5;
    margin: 0;
  }

  &__tooltip {
    position: relative;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }

  &__tooltip &__tooltiptext {
    visibility: hidden;
    text-align: left;
    padding: 20px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    border-radius: 6px;
    background-color: #8e95a5;
    margin-left: -60px;
    min-height: 60px;
    bottom: 40px;
    left: 38px;
    min-width: 150px;
    display: flex;
    align-items: center;

    &--large {
      bottom: 40px;
      min-height: 100px;
      min-width: 300px;
      left: 10px;
    }
  }

  &__tooltip &__tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 19%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #8e95a5 transparent transparent transparent;
  }

  &__tooltip:hover &__tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  &__selected-answer-tooltip {
    border-radius: 6px;
    background-color: #8e95a5;
    padding: 20px;
    transition: opacity 0.3s;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: white !important;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: #8e95a5 transparent transparent transparent;
    }
  }

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    &__info-icon {
      margin: 0 50px 0 20px;
      cursor: pointer;
      width: 30px;
    }
    &__right-side {
      display: flex;
    }

    &__left-side {
      display: flex;
    }

    &__tooltip:hover &__tooltiptext {
      left: 58px;

      &--large {
        left: 30px;
      }
    }
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding: 20px;
    display: block;

    &__heading {
      font-size: 16px;
      min-width: 80px;
      margin-right: 10px;
    }
    &__answer {
      font-size: 16px;
      min-width: 100px;
    }
    &__placeholder {
      align-items: center;
      font-size: 16px;
    }
    &__display-order {
      margin: 0;
    }

    &__hr {
      display: none;
    }

    &__left-side {
      margin: 0 -40px 10px 0;

      &--without-tooltip {
        margin-left: -50px;
      }
    }

    &__right-side,
    &__left-side {
      justify-content: center;
    }

    &__info-icon,
    &__display-order,
    &__check-icon,
    &__tooltip {
      margin: 0 15px 0 0;
      cursor: pointer;
      font-size: 20px;
      align-items: center;
      justify-content: center;
    }

    &__tooltip:hover &__tooltiptext {
      left: 39px;

      &--large {
        left: 11px;
      }
    }
  }
  @media (max-width: 400px) {
    justify-content: center;
  }
}
