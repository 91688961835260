.configurator {
  &-result-header {
    background-color: #DA92A6;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__heading {
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: 1.1px;
      text-align: center;
      color: #ffffff;
      margin: 0;
    }

    @media(max-width: 1023px) {
      &__heading {
        font-size: 18px;
      }
    }

    @media (max-width: 767px) {
      padding: 10px;
    }
  }
}
