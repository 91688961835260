.configurator {
  &-result-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 35px;

    &__search-button {
      padding: 7.5px 15px;
      cursor: pointer;
      border-radius: 3px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: 0.8px;
      display: flex;
      outline: none;
      border: solid 2px #22262f;
      background-color: #22262f;
      color: #f0f1f5;
      margin: 0 0 25px;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }
    }

    &__search-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px !important;
      margin-left: 10px;
    }

    @media(max-width: 1023px) {
      justify-content: center;
    }
  }
}
