.configurator-question {
  width: 100%;
  padding: 0 64px;
  position: relative;
  top: 2px;

  &--active {
    background-color: #fff;
    top: 0;

    .configurator-question__question-wrapper {
      border-bottom: none;
    }
  }

  &__error-wrapper {
    padding: 16px;
    background-color: #fff;
  }

  &__question-wrapper {
    border-bottom: 1px solid #A5C0CF;
  }

  @media(max-width: 1023px) {
    padding: 0;
  }
}
